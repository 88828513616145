import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(
  ({ disabledLink = false, sx, width, height = 36, square = false, ...other }, ref) => {
    const logo = (
      <Box
        component="img"
        src={square ? '/logo/icon.svg' : '/logo/logo_single.svg'}
        sx={{ width, height, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  square: PropTypes.bool,
  sx: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Logo;
