import { COUNTRY_DETAILS_MAP } from './_country_details_map';

export const COUNTRY_CHECKOUT_STEPS = ['Dates', 'Photo', 'Passport', 'Detail', 'Checkout'];
export const APPLICATION_STATUS_STEPS = [
  'Application',
  'Document collection',
  'Processing',
  'Visa Granted',
];

export const ADD_CO_TRAVELLER_STEPS = ['Photo', 'Passport', 'Detail'];

export const DEFAULT_DEPARTURE_COUNTRY = { name: 'India', iso2_code: 'IN' };

export const DATE_CLARITY_TYPES = ['fixed', 'flexible', 'asap'];

export const COUNTRIES = Object.values(COUNTRY_DETAILS_MAP)
  .map((c) => ({
    code: c.code,
    priority: c.priority ?? 10000,
    name: c.country_name,
    price: c.price,
    hero_image_url: c.hero_image_url,
    sticker_visa: c.sticker_visa,
    supported: c.supported,
    purpose: c.purpose,
    visa_free_message: c.visa_free_message ? c.visa_free_message : '',
    packages: c.packages,
  }))
  .sort((a, b) => -a.price + b.price)
  .sort((a, b) => +a.priority - b.priority);
