const { default: axiosInstance, endpoints } = require('src/utils/axios-visa');

/* eslint-disable class-methods-use-this */

class UserService {
  createUser = async (uid, displayName, phoneNumber, email) => {
    const Uid = uid;
    const DisplayName = displayName;
    const PhoneNumber = phoneNumber;
    const Email = email;
    const payload = {
      uid: Uid,
      displayName: DisplayName,
      email: Email,
      phoneNumber: PhoneNumber,
    };

    const resp = await axiosInstance.post(endpoints.user.new, payload);
    return resp.data;
  };

  getUser = async (uid) => {
    const resp = await axiosInstance.get(endpoints.user.getUser(uid));
    return resp.data;
  };
}

const userService = new UserService();
export default userService;
