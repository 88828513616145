import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';
//
import { fileData, fileFormat, fileThumb } from './utils';
import DownloadButton from './download-button';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function FileThumbnail({
  tooltip,
  imageView,
  onDownload,
  sx,
  imgSx,
  type,
  name,
  title,
  src,
  loading,
}) {
  console.log('FileThumbnail', type);
  const format = fileFormat(type);

  const renderContent = (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {format === 'image' && imageView ? (
        <Box
          component="img"
          src={src}
          sx={{
            width: 1,
            height: 1,
            flexShrink: 0,
            objectFit: 'cover',
            ...imgSx,
          }}
        />
      ) : (
        <Box
          component="img"
          src={fileThumb(format)}
          sx={{
            width: 32,
            height: 32,
            flexShrink: 0,
            ...sx,
          }}
        />
      )}
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Iconify icon="eos-icons:loading" />
        </Box>
      )}
    </Box>
  );

  if (tooltip) {
    return (
      <Tooltip title={name}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}
          {onDownload && <DownloadButton onDownload={onDownload} />}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {onDownload && <DownloadButton onDownload={onDownload} />}
    </>
  );
}

FileThumbnail.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  imageView: PropTypes.bool,
  imgSx: PropTypes.object,
  onDownload: PropTypes.func,
  sx: PropTypes.object,
  tooltip: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool,
};
